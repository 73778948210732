import { Injectable } from '@angular/core';

import { catchError, map, tap } from 'rxjs/operators';
import {
  HttpClient,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { SnackbarService } from '../snackBar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private _auth: AuthService,
    private _snackBar: SnackbarService
  ) { }

  // Function which will be called for all http calls
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // How to update the request Parameters
    const url = environment.API_URL + request.url;

    // Add the base api url to the endpoint
    const HEADERS_OBJECT = new HttpHeaders({
      'Content-Type': 'application/json',
      "Paynet-Session-Token": this._auth.sessionId() || '',
      'Accept': 'application/json'
    });
    request = request.clone({
      url,
      headers: HEADERS_OBJECT
    });

    // Logging the updated Parameters to browser's console
    return next.handle(request).pipe(
      tap(
        res => {
          // Logging the http response to browser's console in case of a success
          if (res instanceof HttpResponse) {
            // console.log('api call success :', res);
          }
        },
        error => {
          if (error['error'] && error['error']['sessionExpired']) {
            let errMessage = '';
            if (error['error']['errors'] && error['error']['errors'].length) {
              errMessage = error['error']['errors'][0].longMessage || ''
            }
            this._snackBar.openSnackBar(errMessage);
            this._auth.onLogout();
          }
        }
      )
    );

  }


}
