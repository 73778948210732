import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

export interface Language {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() menuToggle: EventEmitter<any> = new EventEmitter();

  private menuOpen = true;

  constructor(
    private _auth: AuthService
  ) { }

  // Close side menu
  onMenuToggle() {
    this.menuOpen = !this.menuOpen;
    this.menuToggle.emit(this.menuOpen);
  }

  onLogout() {
    this._auth.onLogout()
  }

  ngOnInit() { }

}
