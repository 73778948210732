import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { alehmaMemberSideNav, sideNav } from './side-nav';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Output() sideMenuCose: EventEmitter<boolean> = new EventEmitter();
  sideBarMenu: Array<any>;

  constructor(public authService: AuthService) { }

  onSideMenuClose() {
    this.sideMenuCose.emit(false)
  }

  handleSideNav() {
    if(this.authService.isSuperAdmin()) {
      return sideNav;
    } else {
      return alehmaMemberSideNav;
    }
  }

  ngOnInit() {
    this.sideBarMenu = this.handleSideNav();
  }

}
