export const sideNav = [
  {
    title: "Business Summary",
    route: "/admin",
    icon: "trending_up",
    subMenu: [],
  },
  {
    title: "Transaction",
    route: "/admin/transactions",
    icon: "trending_up",
    subMenu: [],
  },
  {
    title: "Registration",
    route: "/admin/registration",
    icon: "swap_horiz",
    subMenu: [],
  },
  // {
  //   title: "Merchant Configuration",
  //   route: "/merchant-configuration",
  //   icon: "swap_horiz",
  //   subMenu: [],
  // },
  {
    title: "Broker Configuration",
    route: "/admin/broker-configuration",
    icon: "swap_horiz",
    subMenu: [],
  },
  {
    title: "Membership Cards",
    route: "/admin/membership-card",
    icon: "swap_horiz",
    subMenu: [],
  },
  {
    title: "Membership Form",
    route: "/admin/membership-form",
    icon: "swap_horiz",
    subMenu: [],
  },
  {
    title: "User Activity",
    route: "/admin/user-activity",
    icon: "trending_up",
    subMenu: [],
  },
  {
    title: "Merchant Payment Methods",
    route: "/admin/merchant-payment-methods",
    icon: "trending_up",
    subMenu: [],
  },
  {
    title: "Gate-E Merchant Info",
    route: "/admin/gate-merchant-info",
    icon: "trending_up",
    subMenu: [],
  },
  {
    title: "Merchant Transactions",
    route: "/admin/merchant-transactions",
    icon: "trending_up",
    subMenu: [],
  },
  {
    title: "Reward Payments",
    route: "/admin/registration-rewards",
    icon: "trending_up",
    subMenu: [],
  },
  {
    title: "Membership prices",
    route: "/admin/membership-prices",
    icon: "trending_up",
    subMenu: [],
  },
  {
    title: "Settlement Reconciliation Membership Fees",
    route: "/admin/membership-reconciliation",
    icon: "trending_up",
    subMenu: [],
  },
  {
    title: "Settlement Reconciliation Membership Fees with Member reward",
    route: "/admin/membership-reconciliation-member",
    icon: "trending_up",
    subMenu: [],
  },
  {
    title: "Customer loyalty transactions",
    route: "/admin/customer-loyalty-transactions",
    icon: "trending_up",
    subMenu: [],
  },
  // {
  //     title: 'Merchants', route: '/merchants', icon: 'trending_up', subMenu: [
  //         { title: 'List', route: '/merchants', },
  //     ]
  // },

  // { title: 'Refund', route: '/#', icon: 'settings', subMenu: [] },
  // { title: 'Collection', route: '/#', icon: 'settings', subMenu: [] }
];

export const alehmaMemberSideNav = [
  {
    title: "Membership Cards",
    route: "/alhekma-admin",
    icon: "swap_horiz",
    subMenu: [],
  },
  {
    title: "Membership Form",
    route: "/alhekma-admin/membership-form",
    icon: "swap_horiz",
    subMenu: [],
  },
];
