import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  menuToggle = true;

  constructor() { }

  onActivate(event) {
    // window.scroll(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }
  onMenuToggle(event) {
    this.menuToggle = event;
  }

  ngOnInit() { }

}
