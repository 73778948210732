import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// LIBRARIES
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

// SERVICES
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor/interceptor.service';

// ANGULAR MATERIAL
import { MatIconModule, MatSnackBarModule, MatSortModule, MatTableModule} from '@angular/material';
import { MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import {CdkTableModule} from '@angular/cdk/table';

// COMPONENTS
import { HomeComponent } from './components/home/home.component';
import { SideMenuComponent } from './common/side-menu/side-menu.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SnackBarComponent } from './services/snackBar/snack-bar.component';
import { AlhekmaHomeComponent } from './components/alhekma-home/alhekma-home.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AlhekmaHomeComponent,
    SideMenuComponent,
    HeaderComponent,
    FooterComponent,
    SnackBarComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'bank' }),
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    LoadingBarModule,
    LoadingBarRouterModule,
    HttpClientModule,
    MatIconModule,
    MatSelectModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    CdkTableModule,
    MatTableModule
  ],
  exports:[
    CdkTableModule,
    MatTableModule
  ],
  entryComponents: [
    SnackBarComponent
  ],
  providers: [
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'never' } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
