import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginUser } from 'src/app/model/login-user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser = new BehaviorSubject(LoginUser);
  private readonly key = environment.Key;

  constructor(
    private router: Router
  ) {
    if (localStorage.getItem(this.key)) {
      const data = JSON.parse(localStorage.getItem(this.key));
      this.currentUser.next(data);
    }
  }

  // When User login data is save in sessionStorage
  onUserLogin(data) {
    if (data) {
      // console.log(data, 'data')
      this.currentUser.next(data);
      localStorage.setItem(this.key, JSON.stringify(data));
    }
  }

  sessionId() {
    let id = '';
    this.currentUser.subscribe(res => {
      if (res) {
        id = res['sessionId'] ? res['sessionId'] : ''
      }
    })
    return id || ''
  }

  isSuperAdmin() {
    let isSuperAdmin = false;
    let groupId;
    this.currentUser.subscribe(res => {
      if (res) {
        groupId = res['groupId'] ? res['groupId'] : null;
        if(groupId) {
          isSuperAdmin = groupId === 1 ? true : false;
        }
      }
    });
    return isSuperAdmin;

  }
  // remove user from session storage to log user out
  onLogout() {
    this.router.navigate(['/login']);
    this.currentUser.next(null);
    localStorage.removeItem(this.key);
  }

}
