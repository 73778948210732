import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  menuToggle = true;

  constructor() { }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    // console.log(window.innerWidth, typeof (window.innerWidth));
    if (window.innerWidth <= 990) {
      this.menuToggle = false;
    } else {
      this.menuToggle = true;
    }
  }

  onMenuToggle(event) {
    this.menuToggle = !this.menuToggle;
  }

  onSideMenuCose(event) {
    // this.menuToggle = event;
  }

  ngOnInit() {
  }

}
