import { Routes } from '@angular/router';
import { AuthGuard } from '../gaurds/auth.guard';
import { HomeComponent } from '../components/home/home.component';
import { AlhekmaHomeComponent } from '../components/alhekma-home/alhekma-home.component';
import { AlhekmaAuthGuard } from '../gaurds/alhekma-auth.guard';

export const routes: Routes = [
    {
        path: 'admin', component: HomeComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('../components/home/home.module').then(m => m.HomeModule)
            }
        ],
        canActivate: [AuthGuard]
    },
    {
        path: 'alhekma-admin', component: AlhekmaHomeComponent,
        loadChildren: () => import('../components/alhekma-home/alhekma-home.module').then(m => m.AlhekmaHomeModule),
        canActivate: [AlhekmaAuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('../components/login/login.module').then(m => m.LoginModule),

    },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'

    },
    {
        path: '**',
        loadChildren: () => import('../common/not-found/not-found.module').then(m => m.NotFoundModule)
    }

]

